<template>
    <survey-loader v-if="!loaded" full-size spinner-size="lg" />
    <div v-else id="organization-dashboard">
        <div id="organization-dashboard-filter">
            <div>
                <AccordionList open-multiple-items>
                    <h3 class="filter-title">Filters</h3>
                    <AccordionItem default-opened>
                        <template #summary>Closing date</template>
                        <template #icon>+</template>
                        <div class="form-group">
                            <label>From closing date</label>
                            <v-date-picker v-model="dateRangeProjects.start" :locale="locale"
                                :max-date="dateRangeProjects.end">
                                <template v-slot="{ inputEvents }">
                                    <survey-input :model-value="formatProjectDate(dateRangeProjects.start)" readonly
                                        v-on="inputEvents" />
                                </template>
                            </v-date-picker>
                        </div>

                        <div class="form-group">
                            <label>To closing date</label>
                            <v-date-picker v-model="dateRangeProjects.end" :locale="locale"
                                :min-date="dateRangeProjects.start">
                                <template v-slot="{ inputEvents }">
                                    <survey-input :model-value="formatProjectDate(dateRangeProjects.end)" readonly
                                        v-on="inputEvents" />
                                </template>
                            </v-date-picker>
                        </div>
                    </AccordionItem>
                    <AccordionItem>
                        <template #summary>Projects</template>
                        <template #icon>+</template>

                        <p class="project-toggle" @click="toggleProjects">
                            <span v-if="filteredProjects.length > 0">Deselect</span>
                            <span v-else>Select</span>
                            all projects
                        </p>

                        <survey-checkbox v-for="project in projects" :key="`filter_${project.projectId}`"
                            :name="`filter_2_${project.projectId}`" :modelValue="projectSelected(project.projectId)"
                            @input="toggleProject(project.projectId)" class="project-checkbox">
                            <div class="project-checkbox-label">
                                <div>{{ project.projectName }}</div>
                                <div class="text-muted">{{ formatProjectDate(project.closeDate) }}</div>
                            </div>
                        </survey-checkbox>
                    </AccordionItem>
                    <AccordionItem v-if="dashboardData.metaDataScores && dashboardData.metaDataScores.data">
                        <template #summary>Metadata</template>
                        <template #icon>+</template>
                        <div class="form-group">
                            <survey-checkbox v-for="metaDataGroup in dashboardData.metaDataScores.data"
                                :key="`filter_3_${metaDataGroup.groupName}`" :name="`filter_4_${metaDataGroup.groupName}`"
                                :modelValue="metaDataGroupSelected(metaDataGroup.groupName)"
                                @input="toggleMetaDataGroups(metaDataGroup.groupName)" class="view-checkbox">
                                <div class="project-checkbox-label">
                                    <div>{{ metaDataGroup.groupName }}</div>
                                </div>
                            </survey-checkbox>
                        </div>
                    </AccordionItem>
                </AccordionList>
            </div>
        </div>

        <div id="organization-dashboard-report">
            <div class="view-selector">
                <survey-button v-for="viewOption in viewOptions" :key="viewOption.key" class="view-selector-btn"
                    @click="setView(viewOption.key)" :variant="view === viewOption.key ? 'primary' : 'transparent'">
                    {{ viewOption.name }}
                </survey-button>
            </div>

            <div class="organization-dashboard-report-wrapper">
                <template v-if="showReload">
                    <div>
                        <h2 @click="reloadData" class="reload"><span class="icon icon-reload"></span>Reload data with
                            selected filters</h2>
                        <survey-loader v-if="!reloaded" spinner-size="sm" />
                    </div>
                </template>
                <template v-else>
                    <template v-if="view === 'project'">
                        <page>
                            <content-wrapper v-sanitize-html="$t(type + '.INTRO_START_TEAM', { context })" />
                            <survey-title v-sanitize-html="$t(type + '.INTRO_TITLE', { context })" />
                            <content-wrapper v-sanitize-html="$t(type + '.INTRO_TEASER', { context })" />

                            <content-wrapper inverse>
                                {{
                                    $t(`${type}.GENERAL_SCORE_DESC`, {
                                        context,
                                        teamMedian: getOrgMedian(),
                                    })
                                }}
                            </content-wrapper>

                            <bar-chart-wrapper class="fearless-graph" :type="type" :show-quartiles="false"
                                :hide-iteration-picker="false">
                                <template v-for="(project, index) in projects">
                                    <bar-chart v-if="project && projectSelected(project.projectId)" :key="project.projectId"
                                        :scores="project.sections[0]" stacked :label="project.context"
                                        :color="barColor(index)" />
                                </template>
                            </bar-chart-wrapper>
                        </page>

                        <template v-for="key in categoryKeys" :key="`cat_${key}`">
                            <page page-break>
                                <survey-subtitle
                                    v-t="{ path: `${type}.${key.toUpperCase()}_ROLE_TITLE`, args: { context } }" />
                                <content-wrapper
                                    v-t="{ path: `${type}.${key.toUpperCase()}_INTRODUCTION`, args: { context } }" />

                                <content-wrapper inverse>
                                    {{
                                        $t(`${type}.${key.toUpperCase()}_SCORE_DESC`, {
                                            context,
                                            teamMedian: getOrgMedian(key.toUpperCase()),
                                            highestTeamScore: getOrgHighestScore(key.toUpperCase()),
                                            lowestTeamScore: getOrgLowestScore(key.toUpperCase()),
                                        })
                                    }}
                                </content-wrapper>

                                <bar-chart-wrapper class="fearless-graph" :type="type" :show-quartiles="false"
                                    :hide-iteration-picker="false">
                                    <template v-for="(project, index) in projects">
                                        <bar-chart v-if="project && projectSelected(project.projectId)"
                                            :key="project.projectId" :scores="scores(project, CategoryType[key])" stacked
                                            :label="project.context" :color="barColor(index)" />
                                    </template>
                                </bar-chart-wrapper>
                            </page>
                        </template>

                        <template v-for="scoresPerQuestion in orgScores" :key="scoresPerQuestion.groupName">
                            <page page-break>
                                <survey-subtitle>{{ questionTitle(scoresPerQuestion.groupName) }}</survey-subtitle>
                                <content-wrapper inverse v-t="{
                                    path: `${type}.GENERAL_SCORE_DESC`,
                                    args: {
                                        context: questionTitle(scoresPerQuestion.groupName),
                                        teamMedian: scoresMedian(scoresPerQuestion.projects),
                                    },
                                }" />

                                <bar-chart-wrapper class="fearless-graph" :type="type" hide-iteration-picker>
                                    <template v-for="(projectScores, index) in scoresPerQuestion.projects"
                                        :key="scoresPerQuestion.groupName + projectScores.projectId">
                                        <bar-chart v-if="projectSelected(projectScores.projectId)" :scores="projectScores"
                                            stacked :label="projectScores.context" :color="barColor(index)" />
                                    </template>
                                </bar-chart-wrapper>
                            </page>
                        </template>
                    </template>
                    <template
                        v-else-if="dashboardData.metaDataScores && dashboardData.metaDataScores.data && dashboardData.metaDataScores.data.length > 0">
                        <page>
                            <content-wrapper v-sanitize-html="$t(type + '.INTRO_START_TEAM_META', { context })" />
                            <survey-title v-sanitize-html="$t(type + '.INTRO_TITLE_META', { context })" />
                            <content-wrapper v-sanitize-html="$t(type + '.INTRO_TEASER_META', { context })" />

                            <content-wrapper inverse>
                                {{
                                    $t(`${type}.GENERAL_SCORE_DESC`, {
                                        context,
                                        teamMedian: getMedianSelectedMetaDataGroups(),
                                        highestTeamScore: getOrgHighestScoreSelectedMetaDataGroups(),
                                        lowestTeamScore: getOrgLowestScoreSelectedMetaDataGroups(),
                                    })
                                }}
                            </content-wrapper>

                            <bar-chart-wrapper class="fearless-graph" :type="type" :show-quartiles="false"
                                :hide-iteration-picker="false">
                                <template v-for="(scoresPerMetaDataGroup, index) in dashboardData.metaDataScores.data"
                                    :key="scoresPerMetaDataGroup.groupName + '_meta_key'">
                                    <bar-chart v-if="metaDataGroupSelected(scoresPerMetaDataGroup.groupName)"
                                        :scores="mapToScoreObject(scoresPerMetaDataGroup)" stacked
                                        :label="scoresPerMetaDataGroup.groupName" :color="barColor(index)" />
                                </template>
                            </bar-chart-wrapper>
                        </page>

                        <template v-for="key in categoryKeys" :key="`cat_${key}`">
                            <page page-break v-if="dashboardData.metaDataScores && dashboardData.metaDataScores.data">
                                <survey-subtitle
                                    v-t="{ path: `${type}.${key.toUpperCase()}_ROLE_TITLE`, args: { context } }" />
                                <content-wrapper
                                    v-t="{ path: `${type}.${key.toUpperCase()}_INTRODUCTION`, args: { context } }" />

                                <content-wrapper inverse>
                                    {{
                                        $t(`${type}.${key.toUpperCase()}_SCORE_DESC`, {
                                            context,
                                            teamMedian: getMedianSelectedMetaDataGroups(key.toUpperCase()),
                                        })
                                    }}
                                </content-wrapper>

                                <bar-chart-wrapper class="fearless-graph" :type="type" hide-iteration-picker>
                                    <template v-for="(scoresPerMetaDataGroup, index) in dashboardData.metaDataScores.data"
                                        :key="scoresPerMetaDataGroup.groupName + '_meta_key'">
                                        <bar-chart v-if="metaDataGroupSelected(scoresPerMetaDataGroup.groupName)"
                                            :scores="mapToScoreObject(scoresPerMetaDataGroup, key)" stacked
                                            :label="scoresPerMetaDataGroup.groupName" :color="barColor(index)" />
                                    </template>
                                </bar-chart-wrapper>
                            </page>
                        </template>
                        <page>
                            <team-heatmap :categoryKey="'HEATMAP'" :context="context" :type="type"
                                :heatmap="dashboardData.heatmap" />
                        </page>
                    </template>
                    <template v-else>
                        <page>
                            <content-wrapper center>
                                <survey-title v-sanitize-html="$t(type + '.NOT_ENOUGH_METADATA')" />
                            </content-wrapper>
                        </page>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>
<script src="./OrganizationDashboard.ts"></script>
<style lang="scss">
@import '@/variables';

#organization-dashboard {
    display: grid;
    grid-template-columns: 20rem auto;
    gap: 2rem;
    min-width: 1024px;
    width: auto;
    margin: 0 auto;
    height: calc(100vh - 8rem);

    &-filter,
    &-report {
        min-height: 0;
        height: 100%;
    }

    &-filter {
        display: grid;
        grid-template-rows: min-content auto;
        position: sticky;

        .project-filter {
            padding: 1rem;
            overflow: auto;
            flex-grow: 1;

            .form-group {
                margin-bottom: 1rem;
            }

        }

        .project-toggle {
            margin-top: 0;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .filter-title {
        padding: 0 0 0 1rem;
        margin-bottom: 0;
        margin-top: 1rem;
    }

    &-report {
        max-width: $main-max-width;
        min-width: $main-max-width;
        overflow-y: auto;

        .organization-dashboard-report-wrapper {
            padding: 2rem;

            .reload {
                cursor: pointer;
                text-decoration: underline;
                margin: 1rem auto;
                width: 24rem;
                text-align: center;
            }
        }

        .view-selector {
            display: flex;
            overflow: hidden;
            border-bottom: 1px solid #9e9e9e;

            .view-selector-btn {
                width: 100%;
            }
        }
    }
}

.project-checkbox {
    margin-bottom: 1rem;

    .survey-checkbox-label {
        align-items: flex-start;
    }

    &-label {
        display: flex;
        flex-direction: column;
    }
}

.view-checkbox {
    margin-bottom: 0 !important;

    &:first-of-type {
        margin-top: 1rem;
    }

    .survey-checkbox-label {
        margin-right: 1rem;
    }
}
</style>